// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiResponse, CardTransactionDtoListApiDataResponse, CardTransactionStatus } from "../model";

export class CardTransactionsApi extends APIHandler {
    static urls: APIURLTypes.CardTransactionsApi = {
        apiV1AdminCardTransactionsGet: "/api/v1/admin/card-transactions"
    };
    
    constructor() {
        super("CardTransactionsApi");
    }
    
    /**
     * @param transactionProcessingDateFrom Transaction processing date lower bound
     * @param transactionProcessingDateTo Transaction processing date upper bound
     * @param amountFrom Transaction amount lower bound
     * @param amountTo Transaction amount upper bound
     * @param status Transaction status
     * @param workerName Worker name
     * @param companyId Company identifier
     * @param payMonthFrom Pay month lower bound
     * @param payMonthTo Pay month upper bound
     * @param regionId Region where the transaction was made
     * @param cashbackPayoutDateFrom Pay month lower bound
     * @param cashbackPayoutDateTo Pay month upper bound
     * @param cardNumber Card number (masked)
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCardTransactionsGet(params: APIParams & {
        transactionProcessingDateFrom?: string;
        transactionProcessingDateTo?: string;
        amountFrom?: number;
        amountTo?: number;
        status?: CardTransactionStatus;
        workerName?: string;
        companyId?: number;
        payMonthFrom?: string;
        payMonthTo?: string;
        regionId?: number;
        cashbackPayoutDateFrom?: string;
        cashbackPayoutDateTo?: string;
        cardNumber?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<CardTransactionDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CardTransactionsApi.urls.apiV1AdminCardTransactionsGet, null);
        params.options = this.initOptions(params.options, { transactionProcessingDateFrom: params.transactionProcessingDateFrom, transactionProcessingDateTo: params.transactionProcessingDateTo, amountFrom: params.amountFrom, amountTo: params.amountTo, status: params.status, workerName: params.workerName, companyId: params.companyId, payMonthFrom: params.payMonthFrom, payMonthTo: params.payMonthTo, regionId: params.regionId, cashbackPayoutDateFrom: params.cashbackPayoutDateFrom, cashbackPayoutDateTo: params.cashbackPayoutDateTo, cardNumber: params.cardNumber, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CardTransactionDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCardTransactionsGet", params.options, body, "apiV1AdminCardTransactionsGet");
    }

}
