export const Errors = {
  DEFAULT: 'The action was not successful. An unknown error occurred.',
  ERROR_401: 'Your session has expired. Please log in again.',
  ERROR_403: 'You do not have permission to perform the action.',
  ERROR_404: 'Record not found.',
  ERROR_500: 'Internal server error.',
  VALIDATION_FAILED: 'Validation Failed.',
  COMPANY_INVALID: 'Company is invalid.',
  COMPANY_CODE_INVALID: 'Company code was not found.',
  COMPANY_CODE_EXISTS: 'Company with provided code already exists.',
  CONNECTOR_NO_CONNECTION:
    "There is no connection to any of the employer's servers. Please try again later.",
  CONNECTOR_NO_REPLY:
    "The employer's server did not respond in a timely manner. Please try again later.",
  USER_IDENTIFIER_OR_PASSWORD_INVALID: 'Username or password is invalid.',
  USER_OLD_PASSWORD_INVALID: 'Current password is invalid.',
  CONECTOR_REQUEST_FAILED: 'Connector request failed. Try it again later or contact support.',
  BALANCE_EXISTS:
    'The request cannot be edited - a monthly balance has already been created for this month.',
  PAYMENT_ALREADY_PROCESSED: 'Request is already processed.',
  PAYMENT_NOT_VERIFIED: 'Request is not verified.',
  USER_HAS_NO_ROLES: 'The admin user must have a defined role.',
  USERNAME_TAKEN: 'A user with the same username already exists.',
  COMPANY_ADMIN_MUST_HAVE_COMPANY_ASSIGNED: 'Company admin must have a company assigned to it.',
  INVALID_BALANCE_DAY: 'Company balance day has invalid value',
  NO_PAYMENT_BATCHES_CREATED:
    'No payment batches were created. All payments are already being processed by the bank.',
};
