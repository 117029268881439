import {
  useCompanyLookupsList,
  useDemoPaymentExport,
  useDemoPaymentList,
  useNonDemoPaymentExport,
  useNonDemoPaymentList,
  usePaymentGet,
  usePaymentProcess,
  useReconciliatePayments,
  useVerifiedCzechPaymentList,
  useVerifiedPaymentCzechExport,
  useVerifiedPaymentSlovakExport,
  useVerifiedSlovakPaymentList,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { FormBasic, formBasicFields } from './FormBasic';
import { useValidationSchema } from './validation';
import { Props } from './types';
import { FormError, formErrorFields } from './FormError';
import { PaymentState } from '@/api/base/model';
import { CheckCircleOutline } from '@mui/icons-material';
import { ApiKey, Permission } from '@/enums';
import { colorGreenLight, colorOrange } from '@/styles';
import { useMemo } from 'react';
import {
  useApiCeskaSporitelnaVerifyConnection,
  useApiCeskaSporitelnaVerifiedConnectionConnect,
  useApiCeskaSporitelnaVerifiedConnectionBatchProcess,
  useApiCeskaSporitelnaPollBatches,
} from '@/api/methods/ceskaSporitelna';

export const PaymentsModule = ({ flavor }: Props) => {
  const flavorDependentProps = useMemo(() => {
    switch (flavor) {
      case 'Standard':
        return {
          apiKey: ApiKey.Payments,
          useApiList: useNonDemoPaymentList,
          useApiExport: useNonDemoPaymentExport,
        };
      case 'Demo':
        return {
          apiKey: ApiKey.Payments,
          useApiList: useDemoPaymentList,
          useApiExport: useDemoPaymentExport,
        };
      case 'VerifiedCzech':
        return {
          apiKey: ApiKey.PaymentsVerified,
          useApiList: useVerifiedCzechPaymentList,
          useApiVerifyConnection: useApiCeskaSporitelnaVerifyConnection,
          useApiVerifiedConnectionConnect: useApiCeskaSporitelnaVerifiedConnectionConnect,
          useApiVerifiedConnectionBatchProcess: useApiCeskaSporitelnaVerifiedConnectionBatchProcess,
          useReconciliatePayments: useReconciliatePayments,
          useApiGenerateFiles: useVerifiedPaymentCzechExport,
          useApiPollBatches: useApiCeskaSporitelnaPollBatches,
        };
      case 'VerifiedSlovak':
        return {
          apiKey: ApiKey.PaymentsVerified,
          useApiList: useVerifiedSlovakPaymentList,
          useApiGenerateFiles: useVerifiedPaymentSlovakExport,
        };
    }
  }, [flavor]);

  return (
    <Module
      {...{
        ...flavorDependentProps,
        translationKey: 'Payments',
        useApiGet: usePaymentGet,
        useValidationSchema,
        formComponentHeight: 390,
        isEditEnabled: (p) => p.state !== PaymentState.Processed,
        columns: [
          {
            dataKey: 'id',
            label: 'Id',
            type: TableColumnType.Number,
            filterType: TableColumnType.Number,
            filterEnabled: true,
          },
          {
            dataKey: 'dateRequested',
            label: 'DateRequested',
            type: TableColumnType.DateTime,
            filterType: TableColumnType.Date,
            filterEnabled: true,
          },
          {
            dataKey: 'state',
            label: 'State',
            type: TableColumnType.Enum,
            enumName: 'PaymentState',
            enumObject: PaymentState,
            filterEnabled: true,
          },
          {
            dataKey: 'amount',
            label: 'Amount',
            type: TableColumnType.Currency,
            getStyle: (_, item) => (item.bonus ? { backgroundColor: colorOrange } : {}),
          },
          {
            dataKey: 'worker.name',
            label: 'Worker',
            filterEnabled: true,
            filterKey: 'name',
            type: TableColumnType.Text,
          },
          {
            dataKey: 'company.name',
            sortKey: 'company',
            filterKey: 'companyId',
            label: 'Company',
            type: TableColumnType.ApiEnum,
            useApiList: useCompanyLookupsList,
            filterEnabled: true,
          },
          {
            dataKey: 'dateProcessed',
            label: 'DateProcessed',
            type: TableColumnType.DateTime,
          },
          {
            dataKey: 'directDebitId',
            label: 'Debited',
            getStyle: (_, item) => (item.directDebitId ? { backgroundColor: colorGreenLight } : {}),
          },
          {
            dataKey: 'account',
            label: 'Account',
            type: TableColumnType.Text,
            filterEnabled: true,
          },
          {
            dataKey: 'payMonth',
            label: 'PayMonth',
            type: TableColumnType.Month,
            filterEnabled: true,
          },
          { dataKey: 'payMonthSequence', label: 'PayMonthSequence', type: TableColumnType.Number },
          { dataKey: 'companyFee', label: 'CompanyFee', type: TableColumnType.Currency },
          { dataKey: 'workerFee', label: 'WorkerFee', type: TableColumnType.Currency },
          { dataKey: 'paid', label: 'Paid', type: TableColumnType.Currency },
          { dataKey: 'advance', label: 'Advance', type: TableColumnType.Currency },
        ],
        initialValues: {
          amount: 0,
          bonus: 0,
          companyFee: 0,
          workerFee: 0,
          note: '',
        },
        formComponents: [
          {
            label: 'Payment',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Error',
            component: FormError,
            fields: formErrorFields,
          },
        ],
        actions: [
          {
            label: 'PaymentProcess',
            text: 'PaymentProcessConfirmText',
            message: 'PaymentProcessSuccess',
            useAction: usePaymentProcess,
            isEnabled: (p) =>
              p.state === PaymentState.Verified ||
              p.state === PaymentState.InBankLocked ||
              p.state === PaymentState.InBankAuthExpired ||
              p.state === PaymentState.InBankPendingAuth,
            icon: CheckCircleOutline,
            permission: Permission.PaymentsProcess,
          },
        ],
      }}
    />
  );
};
