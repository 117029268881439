export const Companies = {
  BalanceDay: 'Balance day',
  BalanceDayInSameMonth: 'Balance day in same month',
  BalanceDayInSameMonthTooltip:
    'Specifies whether the Consolidation Date in in the same month as pay-month or whether it is in the following month.',
  City: 'City',
  Code: 'Code',
  ConnectorState: 'Connector state',
  Region: 'Region',
  Demo: 'Demo',
  ErpSystem: 'ERP',
  Identifier: 'Identifier',
  Name: 'Name',
  Note: 'Note',
  PayLimitAmount: 'Limit amount',
  PayLimitPercentage: 'Limit %',
  RegularAttendance: 'Negative attendance',
  Company: 'Company',
  Fees: 'Fees',
  Verification: 'User verification',
  FirstVerification: 'User verification No.1',
  SecondVerification: 'User verification No.2',
  BonusAction: 'Bonus',
  FirstCompanyFee: 'Fee for the 1. withdrawal - company',
  FirstWorkerFee: 'Fee for the 1. withdrawal - employee',
  SecondCompanyFee: 'Fee for the 2. withdrawal - company',
  SecondWorkerFee: 'Fee for the 2. withdrawal - employee',
  ThirdCompanyFee: 'Fee for the 3. withdrawal - company',
  ThirdWorkerFee: 'Fee for the 3. withdrawal - employee',
  FourthPlusCompanyFee: 'Fee for the 4. and other withdrawal - company',
  FourthPlusWorkerFee: 'Fee for the 4. and other withdrawal - employee',
  PaymentCardCompanyFee: 'Payment card usage fee - company',
  PaymentCardWorkerFee: 'Payment card usage fee - employee',
  Bonus: 'Bonus',
  BonusFrom: 'Event duration from',
  BonusTo: 'Event duration to',
  OneTime: 'One time',
  RefreshCompany: 'Refresh company',
  RefreshCompanyConfirmText:
    'Are you sure you want to refresh company (worker personal data, attendance)?',
  RefreshCompanySuccess: 'Company refreshed',
  RefreshCompanyDemoData: 'Refresh company demo data',
  RefreshCompanyDemoDataConfirmText: 'Are you sure you want to refresh company demo data?',
  RefreshCompanyDemoDataSuccess: 'Company demo data refreshed',
  CreateBalance: 'Create balance',
  CreateBalanceConfirmText:
    "Are you sure you want to create a balance if it wasn't already created?",
  CreateBalanceSuccess: 'Action completed',
  PaysInExecutionOrInsolvency: 'Payment to persons subject to foreclosure or insolvency',
  EnrollCompanyReferralProgram: 'Enroll the company in the referral program',
  EnrollCompanyReferralProgramConfirmText:
    'Are you sure you want to enroll the company in the referral program?',
  EnrollCompanyReferralProgramSuccess: 'Company enrolled in referral program',
  RemoveCompanyReferralProgram: 'Remove the company from referral program',
  RemoveCompanyReferralProgramConfirmText:
    'Are you sure you want to remove the company from referral program?',
  RemoveCompanyReferralProgramSuccess: 'Company removed from referral program',
  DocumentInfo: 'Information for the document',
  RegistrationType: 'Registration type',
  ExcludeFromBatch: 'Exclude from batch',
  DebtorName: 'Name',
  DebtorBic: 'BIC',
  DebtorIban: 'IBAN',
  DebtorOrgId: 'ID',
  EnrolledInCardProgram: 'Enrolled in card program',
};
