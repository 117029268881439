import {
  useCompanyBalanceCreate,
  useCompanyCreate,
  useCompanyExport,
  useCompanyGet,
  useCompanyList,
  useCompanyRefresh,
  useCompanyRefreshDemoData,
  useCompanyUpdate,
  useEnrollCompanyReferralProgram,
  useRemoveCompanyReferralProgram,
} from '@/api';
import { Module, TableColumnType } from '@/components';
import { getConnectorStateStyle } from '@/styles';
import { FormBasic, formBasicFields } from './FormBasic';
import { FormBonusAction, formBonusActionFields } from './FormBonusAction';
import { FormFees, formFeesFields } from './FormFees';
import { FormNote, formNoteFields } from './FormNote';
import { FormVerification, formVerificationFields } from './FormVerification';
import { useValidationSchema } from './validation';
import { Balance, DoNotTouch, PanTool, Refresh, RestartAlt } from '@mui/icons-material';
import { ApiKey, Permission } from '@/enums';
import { useAppContext } from '@/context';
import { FormDebtor, formDebtorFields } from './FormDebtor';

export const Companies = () => {
  const { hasPermission } = useAppContext();
  return (
    <Module
      {...{
        apiKey: ApiKey.Companies,
        translationKey: 'Companies',
        useApiList: useCompanyList,
        useApiGet: useCompanyGet,
        useApiCreate: useCompanyCreate,
        useApiUpdate: useCompanyUpdate,
        useApiExport: useCompanyExport,
        useValidationSchema,
        formComponentHeight: 560,
        columns: [
          { dataKey: 'id', label: 'Identifier', type: TableColumnType.Number },
          {
            dataKey: 'name',
            label: 'Name',
            filterEnabled: true,
            filterKey: 'companyName',
            type: TableColumnType.Text,
          },
          { dataKey: 'code', label: 'Code' },
          { dataKey: 'regionCode', label: 'Region' },
          { dataKey: 'city', label: 'City' },
          { dataKey: 'demo', label: 'Demo', type: TableColumnType.Boolean },
          {
            dataKey: 'payLimitAmount',
            label: 'PayLimitAmount',
            type: TableColumnType.Currency,
          },
          {
            dataKey: 'payLimitPercentage',
            label: 'PayLimitPercentage',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'regularAttendance',
            label: 'RegularAttendance',
            type: TableColumnType.Boolean,
          },
          {
            dataKey: 'balanceDay',
            label: 'BalanceDay',
            type: TableColumnType.Number,
          },
          {
            dataKey: 'balanceDayInSameMonth',
            label: 'BalanceDayInSameMonth',
            type: TableColumnType.Boolean,
          },
          { dataKey: 'erpSystem', label: 'ErpSystem' },
          {
            dataKey: 'connectorState',
            label: 'ConnectorState',
            type: TableColumnType.Enum,
            enumName: 'ConnectorState',
            getStyle: getConnectorStateStyle,
          },
          { dataKey: 'note', label: 'Note', max: 300 },
        ],
        initialValues: {
          code: '',
          name: '',
          city: '',
          documentInfo: '',
          erpSystem: '',
          note: '',
          regionId: null,
          demo: false,
          regularAttendance: false,
          oneTime: false,
          paysInExecutionOrInsolvency: false,
          balanceDayInSameMonth: false,
          payLimitAmount: 0,
          payLimitPercentage: 0,
          balanceDay: 1,
          firstCompanyFee: 0,
          firstWorkerFee: 0,
          secondCompanyFee: 0,
          secondWorkerFee: 0,
          thirdCompanyFee: 0,
          thirdWorkerFee: 0,
          fourthPlusCompanyFee: 0,
          fourthPlusWorkerFee: 0,
          paymentCardCompanyFee: 0,
          paymentCardWorkerFee: 0,
          enrolledInCardProgram: false,
          bonus: 0,
          bonusFrom: null,
          bonusTo: null,
          firstVerification: {
            caption: '',
            toolTip: '',
            example: '',
            format: '',
          },
        },
        formComponents: [
          {
            label: 'Company',
            component: FormBasic,
            fields: formBasicFields,
          },
          {
            label: 'Fees',
            component: FormFees,
            fields: formFeesFields,
          },
          {
            label: 'Note',
            component: FormNote,
            fields: formNoteFields,
          },
          {
            label: 'Verification',
            component: FormVerification,
            fields: formVerificationFields,
            width: 220,
          },
          {
            label: 'BonusAction',
            component: FormBonusAction,
            fields: formBonusActionFields,
            tabContentDisabled: true,
            width: 180,
          },
          {
            label: 'Debtor',
            component: FormDebtor,
            fields: formDebtorFields,
          },
        ],
        actions: [
          {
            label: 'RefreshCompany',
            text: 'RefreshCompanyConfirmText',
            message: 'RefreshCompanySuccess',
            isEnabled: (p) => !p.demo,
            useAction: useCompanyRefresh,
            icon: Refresh,
            permission: Permission.CompaniesRefresh,
          },
          {
            label: 'RefreshCompanyDemoData',
            text: 'RefreshCompanyDemoDataConfirmText',
            message: 'RefreshCompanyDemoDataSuccess',
            isEnabled: (p) => p.demo,
            useAction: useCompanyRefreshDemoData,
            icon: Refresh,
            permission: Permission.CompaniesRefresh,
          },
          {
            label: 'CreateBalance',
            text: 'CreateBalanceConfirmText',
            message: 'CreateBalanceSuccess',
            useAction: useCompanyBalanceCreate,
            icon: Balance,
            permission: Permission.BalancesCreate,
          },
          {
            label: 'EnrollCompanyReferralProgram',
            text: 'EnrollCompanyReferralProgramConfirmText',
            message: 'EnrollCompanyReferralProgramSuccess',
            isEnabled: (p) => !p.enrolledInReferralProgram,
            useAction: useEnrollCompanyReferralProgram,
            icon: PanTool,
            permission: Permission.CompaniesSave,
          },
          {
            label: 'RemoveCompanyReferralProgram',
            text: 'RemoveCompanyReferralProgramConfirmText',
            message: 'RemoveCompanyReferralProgramSuccess',
            isEnabled: (p) => p.enrolledInReferralProgram,
            useAction: useRemoveCompanyReferralProgram,
            icon: DoNotTouch,
            permission: Permission.CompaniesSave,
          },
        ],
      }}
    />
  );
};
