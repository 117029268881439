// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { RegistrationType } from './registration-type';
import { VerificationQuestionDto } from './verification-question-dto';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CompanyDetailDto extends APIModel {
    /**
    * Company identifier
    */
    id: number;
    /**
    * Company code
    */
    code: string;
    /**
    * Company region id
    */
    regionId: number;
    /**
    * Company name
    */
    name: string;
    /**
    * Company city
    */
    city: string;
    /**
    * Company currency
    */
    currency: string;
    /**
    * Company note
    */
    note: string;
    /**
    * Company ERP system
    */
    erpSystem: string;
    /**
    * Information about company needed for document template
    */
    documentInfo: string;
    /**
    * Company balance day
    */
    balanceDay: number;
    /**
    * Flag if balance day is in same month as pay month
    */
    balanceDayInSameMonth: boolean;
    /**
    * Company pay limit
    */
    payLimitPercentage: number;
    /**
    * Company pay limit amount
    */
    payLimitAmount: number;
    /**
    * First company fee
    */
    firstCompanyFee: number;
    /**
    * First worker fee
    */
    firstWorkerFee: number;
    /**
    * Second company fee
    */
    secondCompanyFee: number;
    /**
    * Second worker fee
    */
    secondWorkerFee: number;
    /**
    * Third company fee
    */
    thirdCompanyFee: number;
    /**
    * Third worker fee
    */
    thirdWorkerFee: number;
    /**
    * Fourth plus company fee
    */
    fourthPlusCompanyFee: number;
    /**
    * Fourth plus worker fee
    */
    fourthPlusWorkerFee: number;
    paymentCardCompanyFee: number;
    paymentCardWorkerFee: number;
    /**
    * Regular attendance
    */
    regularAttendance: boolean;
    /**
    * Company pays workers in execution or in insolvency
    */
    paysInExecutionOrInsolvency: boolean;
    /**
    * Company is demo company
    */
    demo: boolean;
    /**
    * Bonus active from
    */
    bonusFrom: string;
    /**
    * Bonus active to
    */
    bonusTo: string;
    /**
    * Bonus amount
    */
    bonus: number;
    /**
    * One time bonus
    */
    oneTime: boolean;
    /**
    * Company identifier
    */
    identifier: string;
    firstVerification: VerificationQuestionDto;
    secondVerification: VerificationQuestionDto;
    /**
    * Indicates that company is participating in referral program and registration step 2 can have referral code.
    */
    enrolledInReferralProgram: boolean;
    registrationType: RegistrationType;
    /**
    * Indicates whether payments of company should be excluded from batch
    */
    excludeFromBatch: boolean;
    /**
    * Debtor name used in SEPA xml
    */
    debtorName: string;
    /**
    * Debtor BIC used in SEPA xml
    */
    debtorBic: string;
    /**
    * Debtor iban used in SEPA xml
    */
    debtorIban: string;
    /**
    * Debtor organization id used in SEPA xml
    */
    debtorOrgId: string;
    /**
    * Custom salary deduction agreement of company
    */
    salaryDeductionAgreementHtml: string;
    /**
    * Company is enrolled in payment card program
    */
    enrolledInCardProgram: boolean;
    /**
    * Threshold for sum of funds provided to worker that triggers direct debit creation
    */
    directDebitLimit: number;
    /**
    * Direct debits are enabled for company
    */
    directDebitsEnabled: boolean;
    directDebitNotificationsRecipients: Array<string>;
    directDebitsAccount: string;

    constructor(options?: APIModelOptions) {
        super(CompanyDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "code", type: "string" },
            { name: "regionId", type: "number" },
            { name: "name", type: "string" },
            { name: "city", type: "string" },
            { name: "currency", type: "string" },
            { name: "note", type: "string" },
            { name: "erpSystem", type: "string" },
            { name: "documentInfo", type: "string" },
            { name: "balanceDay", type: "number" },
            { name: "balanceDayInSameMonth", type: "boolean" },
            { name: "payLimitPercentage", type: "number" },
            { name: "payLimitAmount", type: "number" },
            { name: "firstCompanyFee", type: "number" },
            { name: "firstWorkerFee", type: "number" },
            { name: "secondCompanyFee", type: "number" },
            { name: "secondWorkerFee", type: "number" },
            { name: "thirdCompanyFee", type: "number" },
            { name: "thirdWorkerFee", type: "number" },
            { name: "fourthPlusCompanyFee", type: "number" },
            { name: "fourthPlusWorkerFee", type: "number" },
            { name: "paymentCardCompanyFee", type: "number" },
            { name: "paymentCardWorkerFee", type: "number" },
            { name: "regularAttendance", type: "boolean" },
            { name: "paysInExecutionOrInsolvency", type: "boolean" },
            { name: "demo", type: "boolean" },
            { name: "bonusFrom", type: "string" },
            { name: "bonusTo", type: "string" },
            { name: "bonus", type: "number" },
            { name: "oneTime", type: "boolean" },
            { name: "identifier", type: "string" },
            { name: "firstVerification", type: "VerificationQuestionDto" },
            { name: "secondVerification", type: "VerificationQuestionDto" },
            { name: "enrolledInReferralProgram", type: "boolean" },
            { name: "registrationType", type: "RegistrationType" },
            { name: "excludeFromBatch", type: "boolean" },
            { name: "debtorName", type: "string" },
            { name: "debtorBic", type: "string" },
            { name: "debtorIban", type: "string" },
            { name: "debtorOrgId", type: "string" },
            { name: "salaryDeductionAgreementHtml", type: "string" },
            { name: "enrolledInCardProgram", type: "boolean" },
            { name: "directDebitLimit", type: "number" },
            { name: "directDebitsEnabled", type: "boolean" },
            { name: "directDebitNotificationsRecipients", type: "Array<string>" },
            { name: "directDebitsAccount", type: "string" }
        ];
    }
}




