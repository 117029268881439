export const Companies = {
  BalanceDay: 'Deň uzávierky',
  BalanceDayInSameMonth: 'Deň uzávierky v rovnaký mesiac',
  BalanceDayInSameMonthTooltip:
    'Specifikace, či je deň konsolidácie vo výplatnom mesiaci alebo v mesiaci nasledujúcom po výplatnom mesiaci.',
  City: 'Mesto',
  Code: 'Kód',
  ConnectorState: 'Stav konektoru',
  Region: 'Región',
  Demo: 'Demo',
  ErpSystem: 'ERP',
  Identifier: 'Identifikátor',
  Name: 'Názov',
  Note: 'Poznámka',
  PayLimitAmount: 'Limit čiastka',
  PayLimitPercentage: 'Limit %',
  RegularAttendance: 'Negatívna dochádzka',
  Company: 'Spoločnosť',
  Fees: 'Poplatky',
  Verification: 'Overenie používateľa',
  FirstVerification: 'Overenie používateľa č.1',
  SecondVerification: 'Overenie používateľa č.2',
  BonusAction: 'Bonusová akcia',
  FirstCompanyFee: 'Poplatok za 1. výber - spoločnosť',
  FirstWorkerFee: 'Poplatok za 1. výber - zamestnanec',
  SecondCompanyFee: 'Poplatok za 2. výber - spoločnosť',
  SecondWorkerFee: 'Poplatok za 2. výber - zamestnanec',
  ThirdCompanyFee: 'Poplatok za 3. výber - spoločnosť',
  ThirdWorkerFee: 'Poplatok za 3. výber - zamestnanec',
  FourthPlusCompanyFee: 'Poplatok za 4. a ďalší výber - spoločnosť',
  FourthPlusWorkerFee: 'Poplatok za 4. a ďalší výber - zamestnanec',
  PaymentCardCompanyFee: 'Poplatok za používánie karty - spoločnosť',
  PaymentCardWorkerFee: 'Poplatok za používánie karty - zamestnanec',
  Bonus: 'Bonus',
  BonusFrom: 'Trvanie akcie od',
  BonusTo: 'Trvanie akcie do',
  OneTime: 'Jednorázovo',
  RefreshCompany: 'Aktualizácia pracovníkov',
  RefreshCompanyConfirmText:
    'Naozaj chcete aktualizovať údaje od zamestnávateľa (údaje, dochádzka)?',
  RefreshCompanySuccess: 'Aktualizácia dokončená',
  RefreshCompanyDemoData: 'Aktualizácia demo údajov spoločnosti',
  RefreshCompanyDemoDataConfirmText: 'Naozaj chcete aktualizovať demo údaje spoločnosti?',
  RefreshCompanyDemoDataSuccess: 'Aktualizácia dokončená',
  CreateBalance: 'Vytvoriť uzávierku',
  CreateBalanceConfirmText: 'Naozaj chcete vytvoriť uzávierku, ak ešte nebola vytvorená?',
  CreateBalanceSuccess: 'Akcia dokončená',
  PaysInExecutionOrInsolvency: 'Povoliť osobám v exekúcii alebo platobnej neschopnosti',
  EnrollCompanyReferralProgram: 'Zaregistrujte spoločnosť do referal programu',
  EnrollCompanyReferralProgramConfirmText:
    'Ste si istý, že chcete spoločnosť zaregistrovať do referal programu?',
  EnrollCompanyReferralProgramSuccess: 'Spoločnosť je zaregistrovaná v referal programe',
  RemoveCompanyReferralProgram: 'Odstráňte spoločnosť z referal programu',
  RemoveCompanyReferralProgramConfirmText: 'Naozaj chcete odstrániť spoločnosť z referal programu?',
  RemoveCompanyReferralProgramSuccess: 'Spoločnosť bola odstránená z referal programu',
  DocumentInfo: 'Informácie pre dokument',
  RegistrationType: 'Typ registrácie',
  ExcludeFromBatch: 'Vylúčiť z dávky',
  Debtor: 'Dlžník',
  DebtorName: 'Názov',
  DebtorBic: 'BIC',
  DebtorIban: 'IBAN',
  DebtorOrgId: 'ID',
  EnrolledInCardProgram: 'Používá platobné karty',
};
