// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ConnectorState } from './connector-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class CompanyDto extends APIModel {
    /**
    * Company identifier
    */
    id: number;
    /**
    * Company code
    */
    code: string;
    /**
    * Company country legislation code
    */
    regionCode: string;
    /**
    * Company name
    */
    name: string;
    /**
    * Company city
    */
    city: string;
    /**
    * Company currency
    */
    currency: string;
    /**
    * Company note
    */
    note: string;
    /**
    * Company ERP system
    */
    erpSystem: string;
    /**
    * Company balance day
    */
    balanceDay: number;
    /**
    * Flag if balance day is in same month as pay month
    */
    balanceDayInSameMonth: boolean;
    /**
    * Company pay limit
    */
    payLimitPercentage: number;
    /**
    * Company pay limit amount
    */
    payLimitAmount: number;
    /**
    * Regular attendance
    */
    regularAttendance: boolean;
    /**
    * Company is demo company
    */
    demo: boolean;
    /**
    * Bonus active from
    */
    bonusFrom: string;
    /**
    * Bonus active to
    */
    bonusTo: string;
    /**
    * Bonus amount
    */
    bonus: number;
    /**
    * One time bonus
    */
    oneTime: boolean;
    connectorState: ConnectorState;
    /**
    * Indicates that company is participating in referral program and registration step 2 can have referral code.
    */
    enrolledInReferralProgram: boolean;
    /**
    * Threshold for sum of funds provided to worker that triggers direct debit creation
    */
    directDebitLimit: number;

    constructor(options?: APIModelOptions) {
        super(CompanyDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "code", type: "string" },
            { name: "regionCode", type: "string" },
            { name: "name", type: "string" },
            { name: "city", type: "string" },
            { name: "currency", type: "string" },
            { name: "note", type: "string" },
            { name: "erpSystem", type: "string" },
            { name: "balanceDay", type: "number" },
            { name: "balanceDayInSameMonth", type: "boolean" },
            { name: "payLimitPercentage", type: "number" },
            { name: "payLimitAmount", type: "number" },
            { name: "regularAttendance", type: "boolean" },
            { name: "demo", type: "boolean" },
            { name: "bonusFrom", type: "string" },
            { name: "bonusTo", type: "string" },
            { name: "bonus", type: "number" },
            { name: "oneTime", type: "boolean" },
            { name: "connectorState", type: "ConnectorState" },
            { name: "enrolledInReferralProgram", type: "boolean" },
            { name: "directDebitLimit", type: "number" }
        ];
    }
}




