export const Companies = {
  BalanceDay: 'Den závěrky',
  BalanceDayInSameMonth: 'Den závěrky v stejný měsíc',
  BalanceDayInSameMonthTooltip:
    'Specificake, jestli je den konsolidace ve výplatním měsíci nebo v měsíci následujícím po výplatním měsíci.',
  City: 'Město',
  Code: 'Kód',
  ConnectorState: 'Stav konektoru',
  Region: 'Region',
  Demo: 'Demo',
  ErpSystem: 'ERP',
  Identifier: 'Identifikátor',
  Name: 'Název',
  Note: 'Poznámka',
  PayLimitAmount: 'Limit částka',
  PayLimitPercentage: 'Limit %',
  RegularAttendance: 'Negativní docházka',
  Company: 'Společnost',
  Fees: 'Poplatky',
  Verification: 'Verifikace uživatele',
  FirstVerification: 'Verifikace uživatele č.1',
  SecondVerification: 'Verifikace uživatele č.2',
  BonusAction: 'Bonusová akce',
  FirstCompanyFee: 'Poplatek za 1. výběr - firma',
  FirstWorkerFee: 'Poplatek za 1. výběr - zaměstnanec',
  SecondCompanyFee: 'Poplatek za 2. výběr - firma',
  SecondWorkerFee: 'Poplatek za 2. výběr - zaměstnanec',
  ThirdCompanyFee: 'Poplatek za 3. výběr - firma',
  ThirdWorkerFee: 'Poplatek za 3. výběr - zaměstnanec',
  FourthPlusCompanyFee: 'Poplatek za 4. a další výběr - firma',
  FourthPlusWorkerFee: 'Poplatek za 4. a další výběr - zaměstnanec',
  PaymentCardCompanyFee: 'Poplatek za používání karty - firma',
  PaymentCardWorkerFee: 'Poplatek za používání karty - zaměstnanec',
  Bonus: 'Bonus',
  BonusFrom: 'Trvání akce od',
  BonusTo: 'Trvání akce do',
  OneTime: 'Jednorázově',
  RefreshCompany: 'Aktualizace pracovníků',
  RefreshCompanyConfirmText:
    'Opravdu chcete aktualizovat data od zaměstnavatele (údaje, docházka)?',
  RefreshCompanySuccess: 'Aktualizace provedena',
  RefreshCompanyDemoData: 'Aktualizace demo dat společnosti',
  RefreshCompanyDemoDataConfirmText: 'Opravdu chcete aktualizovat demo data společnosti?',
  RefreshCompanyDemoDataSuccess: 'Aktualizace provedena',
  CreateBalance: 'Vytvořit závěrku',
  CreateBalanceConfirmText: 'Opravdu chcete vytvořit závěrku, pokud ještě nebyla vytvořena?',
  CreateBalanceSuccess: 'Akce dokončena',
  PaysInExecutionOrInsolvency: 'Výplata osob v exekuci nebo v insolvenci',
  EnrollCompanyReferralProgram: 'Zaregistrujte společnost do referal programu',
  EnrollCompanyReferralProgramConfirmText:
    'Jste si jisti, že chcete společnost zaregistrovat do referal programu?',
  EnrollCompanyReferralProgramSuccess: 'Společnost je zaregistrována v referal programu',
  RemoveCompanyReferralProgram: 'Odstraňte společnost z referal programu',
  RemoveCompanyReferralProgramConfirmText:
    'Opravdu chcete odstranit společnost z referal programu?',
  RemoveCompanyReferralProgramSuccess: 'Společnost byla odstraněna z referal programu',
  DocumentInfo: 'Informace pro dokument',
  RegistrationType: 'Typ registrace',
  ExcludeFromBatch: 'Vyloučit z dávky',
  Debtor: 'Dlužník',
  DebtorName: 'Název',
  DebtorBic: 'BIC',
  DebtorIban: 'IBAN',
  DebtorOrgId: 'ID',
  EnrolledInCardProgram: 'Používá platební karty',
};
