export const Errors = {
  DEFAULT: 'Akcia nebola úspešná. Vyskytla sa neznáma chyba.',
  ERROR_401: 'Platnosť vášho spojenia skončila. Prihláste sa znova.',
  ERROR_403: 'Nemáte oprávnenie vykonať danú akciu.',
  ERROR_404: 'Nenašiel sa žiadny záznam.',
  ERROR_500: 'Interná chyba servera.',
  VALIDATION_FAILED: 'Neboli zadané platné hodnoty.',
  COMPANY_INVALID: 'Spoločnosť je neplatná.',
  COMPANY_CODE_INVALID: 'Kód spoločnosti sa nenašiel.',
  COMPANY_CODE_EXISTS: 'Spoločnosť s poskytnutým kódom už existuje.',
  CONNECTOR_NO_CONNECTION:
    'Neexistuje žiadne pripojenie s žiadnym zo serverov zamestnávateľa. Skúste to prosím neskôr.',
  CONNECTOR_NO_REPLY: 'Server zamestnávateľa neodpovedal včas. Skúste to prosím neskôr.',
  USER_IDENTIFIER_OR_PASSWORD_INVALID: 'Používateľské meno alebo heslo je neplatné.',
  USER_OLD_PASSWORD_INVALID: 'Aktuálne heslo je neplatné.',
  CONECTOR_REQUEST_FAILED:
    'Žiadosť o pripojenie zlyhala. Skúste to prosím neskôr alebo kontaktujte podporu.',
  BALANCE_EXISTS: 'Platbu nie je možné upraviť - pre tento mesiac už bola už urobená uzávierka.',
  PAYMENT_ALREADY_PROCESSED: 'Žiadost je už spracovaná.',
  PAYMENT_NOT_VERIFIED: 'Žiadost nie je overená.',
  USER_HAS_NO_ROLES: 'Admin pouzivateľ musi mať zadefinovanú rolu.',
  USERNAME_TAKEN: 'Použivateľ s rovnakym používateľským učtom už existuje.',
  COMPANY_ADMIN_MUST_HAVE_COMPANY_ASSIGNED: 'Company admin musí mať pridelenú spoločnosť.',
  INVALID_BALANCE_DAY: 'Deň uzávierky spoločnosti má neplatnú hodnotu',
  NO_PAYMENT_BATCHES_CREATED:
    'Neboli vykonané žiadne hromadné platby. Všetky platby sú už spracované v banke.',
};
